.matrix-container {
    display: grid;
    overflow: auto;
    height: 505px;
}

.matrix,
.matrix-col-wrapper {
    display: flex;
    flex-wrap: nowrap;
    z-index: 0;
}

.matrix-col-wrapper {
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -ms-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
}

.matrix-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.matrix-container > .matrix:first-child > .matrix-col {
    min-width: 340px;
    max-width: unset;
    width: calc(100% - 150px);
}

.matrix-item--header,
.matrix-item--device {
    height: 80px;
    min-height: 80px;
    position: sticky;
    position: -webkit-sticky;
    background: white;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--primary-cloudy-blue-color) !important;
    font-weight: bold;
}

.matrix-item--device {
    top: 80px;
    height: 140px;
    min-height: 140px;
    padding-top: 25px !important;
}

.matrix-item--header {
    color: var(--primary-color);
}

.matrix-item--header img {
    width: 15px;
    margin-left: 5px;
    padding-bottom: 3px;
}

.matrix-item--header img:hover {
    cursor: pointer;
}

.matrix-item--show {
    padding-top: 0px !important;
    padding-bottom: 14.8px !important;    
}

.matrix-item--tickettype {
    padding-top: 5px !important;
    padding-bottom: 13px !important;
}

.matrix-item--tickettype:last-child {
    padding-bottom: 23px !important;
}

.matrix-col--fixed-left {
    position: sticky;
    left: 0;
    z-index: 1000;
    background: white;
}

.matrix-item {
    padding: 30px 20px;
    font-size: 16px;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: 0.4px;
    vertical-align: bottom;
    border-bottom: 1px dashed var(--primary-cloudy-blue-color);
}

.matrix-container > .matrix > .matrix-col-wrapper .matrix-col .matrix-item--device,
.matrix-container > .matrix > .matrix-col-wrapper .matrix-col .matrix-item--group {
    background-color: var(--secondary-grey-lightest);
}

.matrix-item .section,
.matrix-item .subsection {
    font-weight: bold;
}

.matrix-item .section {
    font-size: 18px;
}

.matrix-item .subsection {
    font-size: 14px;
    position: relative;
}

.matrix-item--device + .matrix-item .subsection {
    margin-top: 20px;
}

.matrix-item .subsection img {
    margin-top: 5px;
}

.matrix-item .subsection img:hover {
    cursor: pointer;
}

.matrix-item .subsection .tickettype {
    margin-top: 10px;
}

.matrix-item .subsection .tickettype .tickettype-item {
    border-top: 1px dashed var(--primary-cloudy-blue-color);
    padding: 10px 0px 10px 20px;
}

.matrix-container > .matrix > .matrix-col:first-child .matrix-item {
    padding-left: 0 !important;
    color: var(--black-color);
}

.matrix-container > .matrix > .matrix-col-wrapper .matrix-col .matrix-item--device + .matrix-item--group {
    padding-top: 70px !important;
}

.matrix-container .matrix .matrix-col-wrapper .matrix-col .matrix-item--group {
    padding-top: 25px !important;
    padding-bottom: 18px !important;
}

.matrix-item .subsection .show-name-and-date {
    display: inline-block;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
}

.matrix-item .subsection .show-name-and-date span {
    margin-left: 15px;
}

.matrix-col--hidden {
    display: none;
}

.matrix-item label.Switch.forced {
    pointer-events: none;
}

.matrix-item label.Switch ~ img {
    width: 20px;
    margin-left: 10px;
    vertical-align: top;
}

.matrix-item .subsection > span:not(.show-name-and-date):hover ~ .options-menu,
.matrix-item .subsection > span ~ .options-menu:hover,
.matrix-item .subsection .tickettype .tickettype-item > span:not(.tickettype-name):hover ~ .options-menu,
.matrix-item .subsection .tickettype .tickettype-item > span ~ .options-menu:hover {
    display: block;
}

.matrix-item .subsection .options-menu {
    display: none;
    position: absolute;
    top: 25px;
    right: -98px;
    width: 225px;
    text-align: center;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 12px;
    text-transform: uppercase;
    z-index: 99999;
}

.matrix-item .subsection .tickettype .tickettype-item .options-menu {
    top: 40px;
}

.matrix-item .subsection .options-menu ul {
    list-style: none;
    margin: 0;
}

.matrix-item .subsection .options-menu ul li {
    padding: 12px 0;
    position: relative;
}

.matrix-item .subsection .options-menu ul li:hover {
    cursor: pointer;
    background-color: var(--primary-dark-color);
}

.matrix-item .subsection .options-menu ul li:not(:last-child):after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 15px;
    width: 195px;
    border-top: 1px solid var(--secondary-grey-lightest);
}

.matrix-item .subsection .options-menu:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid var(--primary-color);
}

.matrix-container::-webkit-scrollbar {
    height: 0.5em;
}

.matrix-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-grey-lightest);
}

.matrix-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--primary-color);
}

@media all and (max-width: 991px) {
    .matrix-container > .matrix:first-child > .matrix-col {
        width: calc(100% - 100px);
        min-width: calc(100% - 100px);
        max-width: initial;
    }
    .matrix-item {
        padding: 28px 0px;
    }
    .matrix-container {
        width: auto !important;
        max-width: calc(100vw - 38px) !important;
        overflow: auto;
    }
    .matrix-col-wrapper,
    .matrix-col {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
    }

    .device > .info, .devicePlain > .info {
        margin: auto;
    }
}