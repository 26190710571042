.Header {
  background-color: var(--black-color);
  color: var(--white-color);
  height: 82px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 2.5;
  letter-spacing: 1.2px;
  z-index: 1;
}

.Header .logo a,
.Header .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.Header .logo img {
  width: 220px;
}

.Header .user-details ~ span {
  display: inline-block;
  height: 30px;
  cursor: pointer;
}

.Header .nav-left .hamburger-menu {
  padding: 41px 26px;
  width: 84px !important;
  background-color: var(--primary-color);
  display: flex;
  text-align: center;
  cursor: pointer;
  z-index: 5;
}

.Header .nav-left .hamburger-menu .icon-menu {
  height: 18px;
}

.Header .nav-right {
  padding: 26px 40px 26px 0 !important;
}

.Header .nav-right img.icon-more {
  width: 30px;
  height: 5px;
}

.Header .nav-right img.icon-login {
  width: 23px;
  height: 21px;
  margin-right: 10px;
}
.Header .nav-right span:first-child {
  margin-right: 30px;
}

.black {
  background-color: var(--black-color) !important;
}

.Header .icon-doubleLineHamburger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 28px;
  -ms-flex-item-align: center;
  align-self: center;
  position: absolute;
  z-index: 1002;
}
.Header .icon-doubleLineHamburger:hover {
  cursor: pointer;
}
.Header .icon-doubleLineHamburger > div {
  background-color: #000;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  content: "";
  width: inherit;
  height: 2px;
  -webkit-transition: width 250ms cubic-bezier(.2,.6,.3,1),-webkit-transform 250ms cubic-bezier(.2,.6,.3,1);
    transition: width 250ms cubic-bezier(.2,.6,.3,1),-webkit-transform 250ms cubic-bezier(.2,.6,.3,1);
    transition: transform 250ms cubic-bezier(.2,.6,.3,1),width 250ms cubic-bezier(.2,.6,.3,1);
    transition: transform 250ms cubic-bezier(.2,.6,.3,1),width 250ms cubic-bezier(.2,.6,.3,1),-webkit-transform 250ms cubic-bezier(.2,.6,.3,1);
    will-change: transform,width;
} 
.Header .hamburger-menu.black .icon-doubleLineHamburger > div {
  background-color: var(--primary-color);
}
.Header .hamburger-icon-top {
  -webkit-transform: translatey(-8px);
  transform: translatey(-8px);
}
.Header .hamburger-icon-patty {
  -webkit-transform: translatey(1px);
  transform: translatey(1px);
}
.Header .hamburger-icon-bottom {
  -webkit-transform: translatey(10px);
  transform: translatey(10px);
}

/* Start Animating the sidebar icon */
.Header .hamburger-menu.colored .icon-doubleLineHamburger>div {
    background-color: #fff;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.Header .hamburger-menu.colored .icon-doubleLineHamburger .hamburger-icon-top {
    -webkit-transform: translatex(3.5px) rotate(-135deg);
    transform: translatex(3.5px) rotate(-135deg);
    width: 28px;
}
.Header .hamburger-menu.colored .icon-doubleLineHamburger>div {
    background-color: #fff;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.Header .hamburger-menu.colored .icon-doubleLineHamburger .hamburger-icon-patty {
    display: none;
    transition: all 1s ease;
}
.Header .hamburger-menu.colored .icon-doubleLineHamburger>div {
    background-color: #fff;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.Header .hamburger-menu.colored .icon-doubleLineHamburger .hamburger-icon-bottom {
    -webkit-transform: translatex(3.5px) rotate(135deg);
    transform: translatex(3.5px) rotate(135deg);
    width: 28px;
}
/* End Animating the sidebar icon */

@media all and (max-width:991px) {
  .Header {
    top: 0;
    right: 0;
    position: fixed !important;
    left: 0;
    z-index: 11;
  }
  .TopNavigationMenu nav ul > li.no-hamburger {
    margin-left: 0;
  }
  .Header .logo img {
    height: 17vw;
    max-height: 67px;
  }
}

@media screen and (max-width: 767px) { 
  .Header .text-uppercase.user-details {
    display: none
  }
}