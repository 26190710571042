.Login {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("./images/city-background.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 1;
}

.Login:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    opacity: 0.5;
}

.Login .login-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    background-color: var(--white-color);
    padding: 50px 20px;
    border: 1px solid var(--gray-light-color);
    border-radius: 15px;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Login .login-wrapper img {
    width: 269px;
}

.Login .login-wrapper a {
    width: 350px;
    margin-top: 30px;
    padding: 16px 0 12px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 0;
    color: var(--white-color) !important;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.Login .login-wrapper a img {
    width: 20px;
    padding-bottom: 5px;
    margin-left: 10px;
}

.Login .login-wrapper a:hover,
.Login .login-wrapper a:active,
.Login .login-wrapper a:focus {
    background-color: var(--primary-dark-color) !important;
}

.Login .login-wrapper a:hover img {
    animation-name: login-animation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
}

@keyframes login-animation {
    0% {
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
}