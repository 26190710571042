/* The container */
.Switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;
}

.Switch input {display:none;}

.switch-label {
    font-weight: normal;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 50px;
    bottom: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px var(--primary-cloudy-blue-color);
    background-color: var(--secondary-white-color);
    -webkit-transition: .4s;
    transition: .4s;
    height: 25px;
}

.switch-slider:hover {
    background-color: var(--secondary-white-color);
    border: solid 1px var(--secondary-grey-dark-color);
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 1px;
    top: 1px;
    bottom: 5px;
    background-color:  var(--primary-cloudy-blue-color);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider {
    background-color: var(--primary-color);
}

input:focus + .switch-slider {
    box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .switch-slider:before {
    height: 21px;
    width: 21px;
    left: calc(100% - 41px);
    bottom: 0px;
    background-color: var(--secondary-white-color);
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

/* Rounded sliders */
.switch-slider.round {
    border-radius: 34px;
}

.switch-slider.round:before {
    border-radius: 50%;
}

.Switch.disabled {
    cursor: default;
    color: var(--secondary-grey-light-color);
}

.Switch.disabled .switch-slider {
    cursor: default;
    color: var(--secondary-grey-light-color);
    background: var(--disabled);
    background-blend-mode: overlay;
    border: solid 1px var(--primary-cloudy-blue-color);
}

.Switch.disabled .switch-label {
    cursor: default;
}

.Switch.disabled:not(.readonly) .switch-slider:before {
    display: none;
}